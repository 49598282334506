import React, { useContext } from 'react'
import type { Genre, SocialMediaLinks } from '../../../grab/types'
import { MainStylesVars, StyleSheet } from '../stylesheet'
import { Title } from '../components/title'
import { useTranslation } from 'react-i18next'
import { BadgeList, Card, HorizontalBar, IconBtn, IconText, NavigationLink, SocialsList } from '../components/components-lib'
import { MainConfig, useLang } from '../chapito-static'
import { Collapse, ConfigProvider } from 'antd'
import type { GroupOrEvent } from '../components/guest-or-event-card'
import { GuestOrEventCard } from '../components/guest-or-event-card'
import { ResourceImage } from '../components/resource-image'
import { YoutubeEmbed } from '../components/youtube-embed'
import { SpotifyEmbed } from '../components/spotify-embed'
import { LangSwitchDropdown } from '../components/lang-switch-dropdown'
import type { StaticGroup, StaticEvent } from '../types'
import { isNotNullish, keys } from '../../../services/typescript'
import { faAngleLeft, faEnvelope, faLocationDot, faMarker, faPhone, faUser } from '@fortawesome/free-solid-svg-icons'
import css from './guest-or-event.css'

const GuestInfo: React.FC<{ guest: StaticGroup }> = ({ guest }) => {
  const { t } = useTranslation()

  return (
    <>
      <DescriptionSection description={guest.description} genres={guest.genres} />
      {guest.links?.youtubeVideoKey && <YoutubeSection videoKey={guest.links.youtubeVideoKey} />}
      <div className={css['two-cols']}>
        <ArtistLinksSection {...guest} />
        <ArtistOriginSection {...guest} />
      </div>
      <SocialsSection {...guest} />
      {guest.copyright ? (
        <div style={styles.copyright}>
          {t('ARTIST.COPYRIGHT')} @ {guest.copyright}
        </div>
      ) : null}
    </>
  )
}

export const GuestPage: React.FC<{ guest: StaticGroup; events: StaticEvent[] }> = ({ guest, events }) => {
  return (
    <GuestOrEventPage
      primaryGuestOrEvent={guest}
      primaryEvent={events.length > 0 ? events[0] : undefined}
      otherEvents={events.slice(1)}
      spotifyLink={guest.links?.spotify}
    >
      <GuestInfo guest={guest} />
    </GuestOrEventPage>
  )
}

export const EventPage: React.FC<{ event: StaticEvent; guests: StaticGroup[]; relatedEvents: StaticEvent[] }> = ({
  event,
  guests,
  relatedEvents,
}) => {
  const config = useContext(MainConfig)
  const guestsByType = guests.reduce<Record<string, StaticGroup[]>>((acc, guest) => {
    acc[guest.guestType.name] = [...(acc[guest.guestType.name] || []), guest]
    return acc
  }, {})
  return (
    <GuestOrEventPage
      primaryGuestOrEvent={event}
      primaryEvent={event}
      otherEvents={relatedEvents}
      spotifyLink={guests.length === 1 ? guests[0].links?.spotify : undefined}
    >
      <DescriptionSection description={event.description} genres={event.genres} />
      {Object.entries(guestsByType).map(([type, guests]) => (
        <div key={type}>
          <Title level={3}>{type}</Title>
          <ConfigProvider
            theme={{
              token: {
                colorTextBase: config.colorBlockText,
                colorBgContainer: config.colorBackground,
                colorBorder: config.colorBackground,
              },
            }}
          >
            <Collapse
              items={guests.map((guest, index) => ({ ...guestSections(guest), index }))}
              bordered={true}
              style={{ backgroundColor: config.colorBlockBackground }}
              rootClassName={css.collapseRoot}
            />
          </ConfigProvider>
        </div>
      ))}
    </GuestOrEventPage>
  )
}

const GuestOrEventPage: React.FC<
  React.PropsWithChildren<{
    primaryGuestOrEvent: GroupOrEvent
    primaryEvent: StaticEvent | undefined
    otherEvents: StaticEvent[]
    spotifyLink?: string | undefined
  }>
> = ({ primaryGuestOrEvent, primaryEvent, otherEvents, spotifyLink, children }) => {
  const { t } = useTranslation()

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <NavigationLink path={{ page: 'home' }}>
          <IconBtn type="link" icon={faAngleLeft} style={{ border: `1px solid ${MainStylesVars.colorAction}`, color: MainStylesVars.colorAction }}>
            {t('GENERAL.NAV_GO_BACK')}
          </IconBtn>
        </NavigationLink>
        <LangSwitchDropdown />
      </div>

      <div style={styles.body} className={css.guestOrEventPageBody}>
        <div style={styles.columnLeft} className={css.guestOrEventPageColumnLeft}>
          <GuestOrEventCard groupOrEvent={primaryGuestOrEvent} event={primaryEvent} display="large" />
          {otherEvents.length > 0 && <Title level={2}>{t('ARTIST.OTHER_EVENTS')}</Title>}
          {otherEvents.map((ev) => (
            <GuestOrEventCard key={ev.id} groupOrEvent={ev} event={ev} display="small" />
          ))}
          {spotifyLink && <SpotifyEmbed spotifyArtistLink={spotifyLink} style={{ height: 400 }} />}
        </div>

        <div style={styles.columnRight}>{children}</div>
      </div>
    </div>
  )
}

const ArtistLinks: React.FC<Pick<StaticGroup, 'email' | 'phone' | 'address' | 'contact'>> = ({ email, phone, address, contact }) => {
  return [
    contact ? <IconText icon={faUser} value={contact}></IconText> : null,
    phone ? <IconText icon={faPhone} href={`tel:${phone}`} value={phone}></IconText> : null,
    email ? <IconText icon={faEnvelope} href={`mailto:${email}`} value={email}></IconText> : null,
    address ? <IconText icon={faMarker} value={address}></IconText> : null,
  ]
    .filter(isNotNullish)
    .reduce((acc, value, index) => {
      return (
        <>
          {acc}
          {index !== 0 ? <HorizontalBar style={{ marginTop: 3, marginBottom: 3, marginLeft: 40 }} /> : null}
          {value}
        </>
      )
    })
}

const DescriptionSection: React.FC<{ description: string | undefined; genres: Genre[] }> = ({ description, genres }) => {
  const { t } = useTranslation()

  return description ? (
    <>
      <Title level={3}>{t('ARTIST.A_PROPOS')}</Title>
      <Card>
        {genres && <BadgeList labels={genres.map((g) => g.name)} />}
        <div dangerouslySetInnerHTML={{ __html: description }}></div>
      </Card>
    </>
  ) : (
    <></>
  )
}

const ArtistLinksSection: React.FC<Pick<StaticGroup, 'email' | 'phone' | 'address' | 'contact'>> = (props) => {
  const { t } = useTranslation()
  return props.email || props.phone || props.address || props.contact ? (
    <div>
      <Title level={3}>{t('ARTIST.LINKS')}</Title>
      <Card style={styles.artistLinksCard}>
        <ArtistLinks {...props} />
      </Card>
    </div>
  ) : (
    <></>
  )
}

const ArtistOriginSection: React.FC<Pick<StaticGroup, 'origin'>> = ({ origin }) => {
  const { t } = useTranslation()
  return origin ? (
    <div>
      <Title level={3}>{t('ARTIST.ORIGIN')}</Title>
      <Card style={styles.artistLinksCard}>
        <IconText icon={faLocationDot} value={origin} />
      </Card>
    </div>
  ) : (
    <></>
  )
}

const YoutubeSection: React.FC<{ videoKey: NonNullable<SocialMediaLinks['youtubeVideoKey']> }> = ({ videoKey }) => {
  const { t } = useTranslation()
  return (
    <>
      <Title level={3}>{t('ARTIST.VIDEO')}</Title>
      <YoutubeEmbed videoId={videoKey} />
    </>
  )
}

const SocialsSection: React.FC<Pick<StaticGroup, 'links' | 'name'>> = ({ links, name }) => {
  const lang = useLang()
  const { t } = useTranslation()

  return links && keys(links).some((k) => !!links[k]) ? (
    <>
      <Title level={3}>{t('ARTIST.SOCIALS', { artist: name.toLocaleUpperCase(lang) })}</Title>
      <SocialsList socials={links} />
    </>
  ) : (
    <></>
  )
}

const guestSections = (guest: StaticGroup): { label: JSX.Element; children: JSX.Element } => {
  const label = (
    <div style={{ display: 'flex', alignItems: 'center', color: MainStylesVars.colorBlockAction }}>
      {guest.image && <ResourceImage imageId={guest.image} alt="" style={{ maxHeight: 30, marginRight: 10 }} />}
      <div>{guest.name}</div>&nbsp;
      <div>{guest.location}</div>
    </div>
  )

  const children = (
    <div style={styles.columnRight}>
      <GuestInfo guest={guest} />
    </div>
  )
  return { label, children }
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  body: {
    display: 'flex',
    width: '100%',
    gap: 20,
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 30,
  },

  columnLeft: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },

  columnRight: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    flexBasis: 2,
  },

  artistLinksCard: {
    marginTop: 12,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
  },

  guestSection: {
    marginBottom: 10,
    color: MainStylesVars.colorTitle,
  },

  copyright: {
    color: MainStylesVars.colorTitle,
  },
})

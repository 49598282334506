import React, { useContext, useEffect, useMemo, useState } from 'react'
import { I18nextProvider, useTranslation } from 'react-i18next'
import i18next from 'i18next'
import translationFr from './i18n/fr.json'
import translationEn from './i18n/en.json'
import type { ProgramId, GenreId, GroupId, Genre, Program, SceneId, Scene, GuestTypeId, GuestType } from '../../grab/types'
import type { Lang } from '../../services/grab'
import { ConfigProvider } from 'antd'
import { PageBase } from './pages/page-base'
import type {
  StaticData,
  StaticEvent,
  StaticGrabData,
  StaticGroup,
  UntranslatedGrabData,
  SpecificFestivalSettings,
  StaticStyles,
  TranslationUnit,
  UntranslatedStaticPage,
  StaticPage,
} from './types'
import { PageRouter } from './navigation'
import { environment } from './environment'
import { MainFontsNames } from './stylesheet'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab as brandIcons } from '@fortawesome/free-brands-svg-icons'
import { fas as solidIcons } from '@fortawesome/free-solid-svg-icons'
import { far as regularIcons } from '@fortawesome/free-regular-svg-icons'
import { sortEvents } from '../../services/events'
import { CurrentDateContext } from '../../services/date-provider'

type i18nResourceEntry = { translation: TranslationUnit }
type i18nResourceRegistry = { [lang: string]: i18nResourceEntry }

type ChapitoStaticProps = {
  specificSettings?: SpecificFestivalSettings
  pages?: UntranslatedStaticPage[]
  styles: StaticStyles
  fixedRawData?: { [lang: string]: StaticGrabData }
}

export type StaticMainConfig = StaticStyles & StaticGrabData['staticConfig']

// not usable outside of a provider
export const MainConfig = React.createContext<StaticMainConfig>({} as StaticMainConfig)
export const MainData = React.createContext<StaticData>({} as StaticData)

const ExternalResources = {
  fontsLoaded: false,
}

export const useLang = (): Lang => useTranslation().i18n.language as Lang

function isTranslationUnit(strOrTr: TranslationUnit | string): strOrTr is TranslationUnit {
  return typeof strOrTr === 'object'
}

function mergeTranslationUnits(target: TranslationUnit, source: TranslationUnit | undefined): TranslationUnit {
  const merge = { ...target }
  for (const key in source) {
    const [mergeChild, sourceChild] = [merge[key], source[key]]
    if (isTranslationUnit(mergeChild) && isTranslationUnit(sourceChild)) merge[key] = mergeTranslationUnits(mergeChild, sourceChild)
    else merge[key] = sourceChild
  }
  return merge
}

const ChapitoWithData: React.FC<ChapitoStaticProps> = ({ styles, pages, fixedRawData }) => {
  const [staticSiteData, setStaticSiteData] = useState<UntranslatedGrabData>()
  const [rawData, setRawData] = useState<StaticGrabData>()
  const [data, setData] = useState<StaticData>()
  const [config, setConfig] = useState<StaticMainConfig>()
  const now = useContext(CurrentDateContext)()
  const lang = useLang()
  const { t } = useTranslation()
  const [loadingMessage, setLoadingMessage] = useState<string>(t('MISC.LOADING_FETCHING'))

  // load fonts if not in a backoffice environment, the backoffice is responsible
  // for the generation of its own font-faces
  if (!ExternalResources.fontsLoaded && !environment.isBackoffice) {
    ExternalResources.fontsLoaded = true
    const style = document.createElement('style')
    style.innerHTML = `@font-face { font-family: "${MainFontsNames.fontTitle}"; src: url("https://${environment.staticSiteUrl}/fonts/fontTitle.ttf"); }
       @font-face { font-family: "${MainFontsNames.fontText}"; src: url("https://${environment.staticSiteUrl}/fonts/fontText.ttf"); }`
    document.head.appendChild(style)
  }

  // fetch data from source in a real environment, when in the backoffice
  // the data is stored in fixedRawData
  useEffect(() => {
    // if(true) {
    //   setStaticSiteData(require('../../dist/data.json'))
    // } else
    if (!environment.isBackoffice) {
      fetch(`https://${environment.staticSiteUrl}/data.json`)
        .then((glob: Response): Response => {
          if (!glob.ok) throw new Error(glob.statusText)
          return glob
        })
        .then(async (glob: Response): Promise<UntranslatedGrabData> => await (glob.json() as Promise<UntranslatedGrabData>))
        .then((untranslated: UntranslatedGrabData): void => setStaticSiteData(untranslated))
        .catch((err: any): void => setLoadingMessage(t('MISC.LOADING_ERROR', { reason: err })))
    } else if (!fixedRawData) {
      setLoadingMessage(t('MISC.LOADING_NO_DATA_SOURCE'))
    }
    library.add(brandIcons, solidIcons, regularIcons)
  }, [fixedRawData, t])

  // translate data if it was fetched from the static site
  // if it was given by the backoffice simply pick the right version
  useEffect(() => {
    if (fixedRawData) {
      setRawData(fixedRawData[lang])
    } else if (staticSiteData) {
      setRawData({
        ...staticSiteData,
        groups: staticSiteData.groups.map((g) => ({
          ...g,
          name: g.name[lang],
          description: g.description[lang],
        })),
        events: staticSiteData.events.map((ev) => ({
          ...ev,
          title: ev.title[lang],
          description: ev.description[lang],
        })),
        scenes: staticSiteData.scenes.map((s) => ({
          ...s,
          name: s.name[lang],
        })),
        programs: staticSiteData.programs.map((p) => ({
          ...p,
          name: p.name[lang],
        })),
        poiList: staticSiteData.poiList.map((poi) => ({
          ...poi,
          label: poi.label[lang],
        })),
        genres: staticSiteData.genres.map((genre) => ({
          ...genre,
          name: genre.name[lang],
        })),
        guestTypes: staticSiteData.guestTypes.map((guestType) => ({
          ...guestType,
          name: guestType.name[lang],
        })),
      })
    }
  }, [staticSiteData, fixedRawData, lang])

  // adapt data from grab
  useEffect(() => {
    if (!rawData) return
    // pre-sort items, it realy only matters for scenes and genres as guests and events are sorted
    // using a user-defined criteria
    rawData.scenes.sort((s1, s2) => s1.name.localeCompare(s2.name))
    rawData.genres.sort((g1, g2) => g1.name.localeCompare(g2.name))
    rawData.events.sort((e1, e2) => e1.title.localeCompare(e2.title))
    rawData.groups.sort((g1, g2) => g1.name.localeCompare(g2.name))

    const data = {
      ...rawData,
    }

    // replace ids by actual object instances
    // there are circular references so some fields must be filled after-the-fact (ie. StaticGroup#relatedEvents)

    const createIdMap = <Id extends string, Obj>(objs: Obj[], idKey: (o: Obj) => Id): Record<Id, Obj> =>
      objs.reduce((acc, obj) => Object.assign(acc, { [idKey(obj)]: obj }), {}) as Record<Id, Obj>
    const mapIdsToObjects = <Id extends string, Obj>(ids: Id[] | undefined, map: Record<Id, Obj>): Obj[] =>
      ids?.map((id) => map[id]).filter((g) => !!g) ?? []
    const mapIdToObject = <Id extends string, Obj>(id: Id, map: Record<Id, Obj>): Obj => map[id]

    const mappedScenes = data.scenes.map((s) => {
      const poi = data.poiList.find((poi) => poi.entityType === 'SCENE' && poi.entityId === s._id)
      return {
        ...s,
        addressPlaceLatitude: poi?.coords.lat,
        addressPlaceLongitude: poi?.coords.lng,
      }
    })
    const programsMap: Record<ProgramId, Program> = createIdMap(data.programs, (p) => p._id)
    const scenesMap: Record<SceneId, Scene> = createIdMap(mappedScenes, (s) => s._id)
    const genresMap: Record<GenreId, Genre> = createIdMap(data.genres, (g) => g._id)
    const guestTypesMap: Record<GuestTypeId, GuestType> = createIdMap(data.guestTypes, (t) => t._id)
    const mappedGroups: StaticGroup[] = data.groups.map((g) => ({
      ...g,
      genres: mapIdsToObjects(g.genres, genresMap),
      guestType: mapIdToObject(g.typeId, guestTypesMap),
      relatedEvents: [
        /* delayed */
      ],
    }))
    const groupsMap: Record<GroupId, StaticGroup> = createIdMap(mappedGroups, (g) => g._id)
    const mappedEvents: StaticEvent[] = sortEvents(
      data.events.map((e) => ({
        ...e,
        genres: mapIdsToObjects(e.genres, genresMap),
        musicGroups: mapIdsToObjects(e.musicGroupsIds, groupsMap),
        scene: mapIdToObject(e.sceneId, scenesMap),
        program: mapIdToObject(e.programId, programsMap),
        spotifyGroups: mapIdsToObjects(e.musicGroupsIds, groupsMap).filter((g) => g.links?.spotify), // FIXME use feature toggle spotify
      })),
      data.staticConfig.alwaysShowEventHour,
      data.staticConfig.showEventHourDelay,
      now,
      data.staticConfig.orderEventsByEndDate,
      true
    )
    mappedGroups.forEach((g) => (g.relatedEvents = mappedEvents.filter((e) => e.musicGroups.includes(g))))
    mappedEvents.forEach((e) => (e.weight = Math.max(...(e.musicGroups.map((group) => group.weight ?? 0) ?? [0]))))

    const translatedPages: StaticPage[] =
      pages
        // do not show timeline page if hours are hidden
        ?.filter((p) => p.view !== 'TIMELINE' || (rawData.staticConfig.alwaysShowEventHour && !rawData.staticConfig.eventsHideEndDate))
        .map((page) => ({
          ...page,
          blockName: page.blockName[lang],
        })) ?? []

    setData({
      genres: data.genres,
      programs: data.programs,
      groups: mappedGroups,
      events: mappedEvents,
      scenes: mappedScenes,
      pages: translatedPages,
    })
  }, [lang, pages, rawData])

  // load config
  useEffect(() => {
    if (!rawData) return
    setConfig({
      ...styles,
      ...rawData.staticConfig,
    })
  }, [styles, rawData])

  if (!data || !config) return <LoadingPage styles={styles}>{loadingMessage}</LoadingPage>

  return (
    <MainConfig.Provider value={config}>
      <MainData.Provider value={data}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: config.colorBlockAction,
              colorTextBase: config.colorBlockAction,
            },
          }}
        >
          <PageBase>
            <PageRouter defaultView={pages?.findIndex((p) => p.default) ?? 0} />
          </PageBase>
        </ConfigProvider>
      </MainData.Provider>
    </MainConfig.Provider>
  )
}

const LoadingPage: React.FC<React.PropsWithChildren<{ styles: StaticStyles }>> = ({ styles, children }) => (
  <div
    style={{
      backgroundColor: styles.colorBackground,
      width: '100%',
      minHeight: '50vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <h2 style={{ color: styles.colorTitle, fontFamily: `${MainFontsNames.fontTitle}, sans-serif` }}>{children}</h2>
  </div>
)

// Root component of the project
// note that this component cannot be used as-is with react because we need
// a ref to the container DOM element, instead use <chapito-static/> after
// having imported index.tsx
export const ChapitoStatic: React.FC<ChapitoStaticProps> = (props) => {
  const { specificSettings } = props

  // initialize i18next
  useMemo(() => {
    // ...using the default translation units and/or the festival's
    const developLangIfNeeded = (lang: Lang, originalTranslation: TranslationUnit): i18nResourceRegistry =>
      specificSettings?.locales?.includes(lang) ?? true
        ? { [lang]: { translation: mergeTranslationUnits(originalTranslation, specificSettings?.i18n?.[lang]) } }
        : {}

    const i18nResources: i18nResourceRegistry = {
      ...(specificSettings?.i18n
        ? Object.keys(specificSettings.i18n).reduce<i18nResourceRegistry>(
            (acc, lang) => Object.assign(acc, { [lang]: { translation: specificSettings.i18n![lang] } }),
            {}
          )
        : {}),
      ...developLangIfNeeded('fr', translationFr),
      ...developLangIfNeeded('en', translationEn),
    }

    i18next.init({
      interpolation: { escapeValue: false },
      resources: i18nResources,
      fallbackLng: {
        default: specificSettings?.locales ?? Object.keys(i18nResources),
      },
    })
  }, [specificSettings?.i18n, specificSettings?.locales])

  return (
    <I18nextProvider i18n={i18next}>
      <ChapitoWithData {...props} />
    </I18nextProvider>
  )
}
